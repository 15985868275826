<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" persistent max-width="600px">
            <v-card>
                <v-card-title>
                    <span class="text-h5">User Profile</span>
                </v-card-title>
                <v-card-text>
                    <v-container class="lfont">
                        <v-row>
                            <v-col cols="12" sm="12" md="12">
                                <v-text-field label="ຄຸນນະສົມບັດ" required v-model="properties"></v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="add">
                        Add
                    </v-btn>
                    <v-btn color="red darken-1" text @click="$emit('dialog', false)">
                        Close
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
export default {
    props: {
        dialog: ""
    },
    data: () => ({
        properties: ""
    }),
    methods: {
        add() {
            if (this.properties == '') return;
            this.$emit('add', this.properties);
            this.$emit('dialog', false);
            this.properties = '';
        }
    },
}
</script>